import { Injectable } from '@angular/core';
import { AlertController, LoadingController, ToastController } from '@ionic/angular';

import { GlobalVariable } from '../service/global';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  loading: HTMLIonLoadingElement | any;
  load: boolean=true;
  FilterModal: HTMLIonModalElement;

  constructor(private alertController: AlertController, private loadingCtrl: LoadingController, public toastController: ToastController,
    public global: GlobalVariable) { }

  async presentAlert(header, message, buttons, disableOuterClick?) {
    const alert = await this.alertController.create({
      header: header,
      message: message,
      cssClass: "network-alert-css",
      buttons: buttons,
      backdropDismiss: disableOuterClick
    });
    await alert.present();
  }

  async internetAlert(header, message) {
    if (!this.global.internetAlertActive) {
      this.global.internetAlertActive = true;
      this.global.internetAlert = await this.alertController.create({
        header: header,
        message: message,
        cssClass: "network-alert-css",
        backdropDismiss: false
      });
      await this.global.internetAlert.present();
    }
  }

  async showLoading(message?) {
    if (this.load == true && !this.loading) {
      this.loading = 1;
      this.load = false;
      this.loading = await this.loadingCtrl.create({
        message: message,
        cssClass: 'loadingCss'
      });
      this.loading.present();
    }
  }

  stopLoading() {
    setTimeout(() => {
        this.loading.dismiss();
        this.loading = undefined;
        this.load = true;
    }, 500);
  }

  async presentToast(message, duration, color, position) {
    const toast = await this.toastController.create({
      message: message,
      duration: duration,
      color: color,
      position: position
    });
    toast.present();
  }

  async superhogFailureAlert(heading, message) {
    const alert = await this.alertController.create({
      header: heading,
      message: message,
      buttons: [
        {
          text: 'OK',
          role: 'confirm',
          handler: () => {
            (navigator as any)['app'].exitApp();
          },
        },
      ],
    });

    await alert.present();
  }  
}

