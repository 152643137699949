// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiBaseUrl: 'https://appserver.connecthedocks.com/api/v1/',
  // apiBaseUrl: 'https://staging3.sdsol.com/ConnectTheDock/api/v1/',
  appVersion: '1.0',
  authUsername: 'sdsol',
  authPassword: 'ConnectTheDock99#$',
  termConditionURL: "https://appserver.connecthedocks.com/content/upload/TermsOfUse.html",
  SUCCESS_STRIPE: "Webhook/StripeWebHook",
  FAILED_STRIPE: "Webhook/StripeLinkExpired",
  googleApiKey: "AIzaSyBIt-SkjIIthsD3qiqtsmrZhE9iG_uEwDM",

  accountPrivacyPolicy: "https://appserver.connecthedocks.com/content/upload/privacy-policy.html",
  headerInsuranceProtection: "https://appserver.connecthedocks.com/Content/Upload/FAQ-Insurance-Protection.html",
  instagramLink: "https://www.instagram.com/connecthedocks/",
  facebookLink: "https://www.facebook.com/connecthedocks/",
  linkedInLink: "https://www.linkedin.com/company/connecthedocks/",
  twitterLink: "",
  youtubeLink: "",
  footerDockageAgreement: "https://appserver.connecthedocks.com/Content/Upload/dockage-agreement.html",
  footerPrivacyPolicyLink: "https://appserver.connecthedocks.com/content/upload/privacy-policy.html",

  androidAppLink: "https://play.google.com/store/apps/details?id=com.app.connecthedocks",
  iosAppLink: "https://apps.apple.com/us/app/connecthedocks/id6467874106"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
