/* eslint-disable eol-last */
/* eslint-disable quote-props */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/quotes */
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { Platform } from '@ionic/angular';

import { environment } from '../../environments/environment';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  loading: any;
  username: String;
  password: String;
  constructor(public loadingCtrl: LoadingController, public platform: Platform) {
    this.username = 'sdsol';
    this.password = 'ConnectTheDock99#$';
  }

  baseAuth() {
    var token = environment.authUsername + ':' + environment.authPassword;
    var hash = btoa(token);
    return 'Basic ' + hash;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //Authentication by setting header with token value
    if (this.baseAuth()) {
      request = request.clone({
        setHeaders: {
          'Authorization': this.baseAuth()
        }
      });
    }

    // if (!request.headers.has('Content-Type')) {
    //   request = request.clone({
    //     setHeaders: {
    //       'content-type': 'application/json'
    //     }
    //   });
    // }
    request = request.clone({
      headers: request.headers.set('Access-Control-Allow-Origin', 'appserver.connecthedocks.com')
    });
    request = request.clone({
      headers: request.headers.set('Access-Control-Allow-Origin', 'staging3.sdsol.com')
    });
    request = request.clone({
      headers: request.headers.set('Access-Control-Allow-Origin', 'localhost')
    });
    request = request.clone({
      headers: request.headers.set('Accept', 'application/json')
    });
    // request = request.clone({
    //   headers: request.headers.set('User-Agent', this.platform.is('ios') ? "iOS" : "Android")
    // });
    request = request.clone({
      headers: request.headers.set('LanguageCode', window.navigator.language)
    });
    // request = request.clone({
    //   headers: request.headers.set('Accept-Encoding', 'gzip, deflate, br')
    // });
    // this.show();
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // console.log('event--->>>', event);
        }
        //   this.hide();
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        //  this.hide();
        return throwError(error);
      }));
  }

  async show() {
    const loading = await this.loadingCtrl.create({
      message: 'Dismissing after 3 seconds...',
      duration: 3000,
    });

    loading.present();

  }
  hide() {
    this.loadingCtrl.dismiss();
  }

}