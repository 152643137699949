import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { NavController } from '@ionic/angular';

import { GlobalVariable } from 'src/app/service/global';

@Injectable({
  providedIn: 'root'
})
export class MainGuardGuard implements CanActivate {
  
  constructor(public global: GlobalVariable, private navCtrl: NavController) { 

  }
  
  async canActivate(): Promise<boolean> {
    const authed = await this.global.platformPWA;
    if (authed) {
     
      return true;
    } else {
      console.log("Main Guard Platform -> ", this.global.user)
      if(!this.global.platformPWA){
        if(!this.global.user){
          this.navCtrl.navigateRoot('tabs');
        } else {
          if(this.global.user.SwitchUserType == "BoatOwner"){
            this.navCtrl.navigateRoot('tabs');
          } else {
            this.navCtrl.navigateRoot('tabs/booked-rentals');
          }
        }
      } else {
        if(!this.global.user){
          this.navCtrl.navigateRoot('home');
        } else {
          if(this.global.user.SwitchUserType == "BoatOwner"){
            this.navCtrl.navigateRoot('home');
          } else {
            this.navCtrl.navigateRoot('booked-rentals');
          }
        }
      }
      return false;
    }
  }
}
