import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class RestApiService {
  public update = new Subject<any>();
  deviceToken: any;

  public dataUpdatedBoatOBS: Subject<any> = new Subject<any>();
  public dataUpdatedDockOBS: Subject<any> = new Subject<any>();
  public inAppPageOBS: Subject<any> = new Subject<any>();
  
  constructor(private http: HttpClient) { }

  apiCall(requestType: string, endpoint: string, body: any): Observable<any> {
    var options = { body: body }
    console.log(JSON.stringify(body));
    return this.http.request(requestType, environment.apiBaseUrl + endpoint, options);
  }

  apiGet(endpoint: string, params: any): Observable<any>{
    return this.http.get(environment.apiBaseUrl + endpoint, { params: params })
  }

  publishSomeData(data: any) { 
    this.update.next(data); 
  }

  getObservable(): Subject<any> {  
    return this.update; 
  }
}
