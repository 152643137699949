import { Component, OnInit, ElementRef } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';

import { filter, startWith, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

// Services
import { RestApiService } from './../../service/rest-api.service';
import { UtilsService } from '../../service/utils.service';
import { GlobalVariable } from '../../service/global';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-pending-review',
  templateUrl: './pending-review.page.html',
  styleUrls: ['./pending-review.page.scss'],
})
export class PendingReviewPage implements OnInit {
  private ngUnsubscribe = new Subject<void>();
  message: any = '';
  rating: any = 1;
  detail: any;

  constructor(private modalCtrl: ModalController, public global: GlobalVariable, private navParams: NavParams,
    public restApi: RestApiService, public utils: UtilsService, public translate: TranslateService, private elementRef: ElementRef) { }

  ngOnInit() {
    this.detail = this.navParams.data!['detail'];
    console.table("Pending Review -> ", this.detail);
  }

  ionViewWillLeave() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  async capitalizeChange(){
    if(this.message.length > 0){
      let capitalizedText = this.elementRef.nativeElement.querySelector('#message');
      capitalizedText.value = this.global.capitalizeSentences(capitalizedText.value);
    }
  }

  async ratingChanged(e) {
    console.log("Rating Star -> ", e);
    this.rating = e;
  }

  async submitReview() {
    let body = {
      "ToUserID": this.detail.ToUserID,
      "BookingID": this.detail.DockBookingId,
      "FromUserID": this.global.user.UserID,
      "Rating": this.rating,
      "Description": this.message
    }

    this.utils.showLoading(this.translate.instant('pleaseWait'));

    this.restApi.apiCall('POST', 'Users/SubmitSessionReview', body)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.utils.stopLoading();
        console.log("Pending Review API -> ", res)
        if (res.response.Code == 0) {
          this.utils.presentToast(res.response.Status, 2000, 'success', 'bottom');

          if (this.global.user.SwitchUserType == "BoatOwner") {
            if(this.global.boatOwnerPendingRating.length > 0){
              this.global.boatOwnerPendingRating.shift();
            }
          } else {
            if(this.global.dockOwnerPendingRating.length > 0){
              this.global.dockOwnerPendingRating.shift();
            }
          }

          this.modalCtrl.dismiss({
            success: true
          });
        }

        if (res.response.Code == 4) {
          this.utils.presentToast(res.response.Status, 2000, 'danger', 'bottom');
        }
      }, error => {
        this.utils.stopLoading();
        this.utils.presentToast(error.error.title, 2000, 'danger', 'bottom');
      });
  }
}
