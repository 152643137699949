import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { MainGuardGuard } from 'src/app/service/guard/main-guard.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule),
    canActivate: [MainGuardGuard]
  },
  {
    path: 'tabs',
    loadChildren: () => import('./pages/tabs/tabs.module').then( m => m.TabsPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./auth/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'tutorial',
    loadChildren: () => import('./pages/tutorial/tutorial.module').then( m => m.TutorialPageModule)
  },
  {
    path: 'sign-up',
    loadChildren: () => import('./auth/sign-up/sign-up.module').then( m => m.SignUpPageModule)
  },
  {
    path: 'term-condition',
    loadChildren: () => import('./auth/term-condition/term-condition.module').then( m => m.TermConditionPageModule)
  },
  {
    path: 'forget-password',
    loadChildren: () => import('./auth/forget-password/forget-password.module').then( m => m.ForgetPasswordPageModule)
  },
  {
    path: 'my-account',
    loadChildren: () => import('./pages/my-account/my-account.module').then( m => m.MyAccountPageModule)
  },
  {
    path: 'otp',
    loadChildren: () => import('./auth/otp/otp.module').then( m => m.OtpPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'edit-info',
    loadChildren: () => import('./pages/edit-info/edit-info.module').then( m => m.EditInfoPageModule)
  },
  {
    path: 'messages',
    loadChildren: () => import('./pages/messages/messages.module').then( m => m.MessagesPageModule)
  },
  {
    path: 'message-details',
    loadChildren: () => import('./pages/message-details/message-details.module').then( m => m.MessageDetailsPageModule)
  },
  {
    path: 'update-password',
    loadChildren: () => import('./auth/update-password/update-password.module').then( m => m.UpdatePasswordPageModule)
  },
  {
    path: 'dock-details',
    loadChildren: () => import('./pages/dock/dock-details/dock-details.module').then( m => m.DockDetailsPageModule)
  },
  {
    path: 'photo-gallery',
    loadChildren: () => import('./pages/photo-gallery/photo-gallery.module').then( m => m.PhotoGalleryPageModule)
  },
  {
    path: 'rentals',
    loadChildren: () => import('./pages/boat/rentals/rentals.module').then( m => m.RentalsPageModule)
  },
  {
    path: 'add-dock',
    loadChildren: () => import('./pages/dock/add-dock/add-dock.module').then( m => m.AddDockPageModule)
  },
  {
    path: 'search',
    loadChildren: () => import('./pages/search/search.module').then( m => m.SearchPageModule)
  },
  {
    path: 'filters',
    loadChildren: () => import('./modal/filters/filters.module').then( m => m.FiltersPageModule)
  },
  {
    path: 'usertype',
    loadChildren: () => import('./auth/usertype/usertype.module').then( m => m.UsertypePageModule)
  },
  {
    path: 'my-docks',
    loadChildren: () => import('./pages/dock/my-docks/my-docks.module').then( m => m.MyDocksPageModule)
  },
  {
    path: 'stripe',
    loadChildren: () => import('./pages/payment/stripe/stripe.module').then( m => m.StripePageModule)
  },
  {
    path: 'payment-settings',
    loadChildren: () => import('./pages/payment/payment-settings/payment-settings.module').then( m => m.PaymentSettingsPageModule)
  },
  {
    path: 'booked-rentals',
    loadChildren: () => import('./pages/dock/booked-rentals/booked-rentals.module').then( m => m.BookedRentalsPageModule)
  },
  {
    path: 'add-boats',
    loadChildren: () => import('./pages/boat/add-boats/add-boats.module').then( m => m.AddBoatsPageModule)
  },
  {
    path: 'boat-details',
    loadChildren: () => import('./pages/boat/boat-details/boat-details.module').then( m => m.BoatDetailsPageModule)
  },
  {
    path: 'my-boats',
    loadChildren: () => import('./pages/boat/my-boats/my-boats.module').then( m => m.MyBoatsPageModule)
  },
  {
    path: 'checkout',
    loadChildren: () => import('./pages/checkout/checkout.module').then( m => m.CheckoutPageModule)
  },
  {
    path: 'card-info',
    loadChildren: () => import('./pages/payment/card-info/card-info.module').then( m => m.CardInfoPageModule)
  },
  {
    path: 'booked-rental-detail',
    loadChildren: () => import('./pages/dock/booked-rental-detail/booked-rental-detail.module').then( m => m.BookedRentalDetailPageModule)
  },
  {
    path: 'rental-details',
    loadChildren: () => import('./pages/boat/rental-details/rental-details.module').then( m => m.RentalDetailsPageModule)
  },
  {
    path: 'performance',
    loadChildren: () => import('./pages/performance/performance.module').then( m => m.PerformancePageModule)
  },
  {
    path: 'in-app-pages',
    loadChildren: () => import('./pages/in-app-pages/in-app-pages.module').then( m => m.InAppPagesPageModule)
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./pages/contact-us/contact-us.module').then( m => m.ContactUsPageModule)
  },
  {
    path: 'how-it-work',
    loadChildren: () => import('./pages/how-it-work/how-it-work.module').then( m => m.HowItWorkPageModule)
  },
  {
    path: 'pending-review',
    loadChildren: () => import('./modal/pending-review/pending-review.module').then( m => m.PendingReviewPageModule)
  },
  {
    path: 'wallet',
    loadChildren: () => import('./pages/wallet/wallet.module').then( m => m.WalletPageModule)
  },
  {
    path: 'trigger-webhook',
    loadChildren: () => import('./pages/trigger-webhook/trigger-webhook.module').then( m => m.TriggerWebhookPageModule)
  },
  {
    path: 'receipt',
    loadChildren: () => import('./pages/receipt/receipt.module').then( m => m.ReceiptPageModule)
  },
  {
    path: 'select-boat',
    loadChildren: () => import('./pages/boat/select-boat/select-boat.module').then( m => m.SelectBoatPageModule)
  },
  {
    path: 'how-it-work-dock',
    loadChildren: () => import('./pages/how-it-work-dock/how-it-work-dock.module').then( m => m.HowItWorkDockPageModule)
  },
  {
    path: 'how-it-work-boat',
    loadChildren: () => import('./pages/how-it-work-boat/how-it-work-boat.module').then( m => m.HowItWorkBoatPageModule)
  },
  {
    path: 'report-user',
    loadChildren: () => import('./modal/report-user/report-user.module').then( m => m.ReportUserPageModule)
  },
  {
    path: 'custom-card',
    loadChildren: () => import('./modal/custom-card/custom-card.module').then( m => m.CustomCardPageModule)
  },
  {
    path: 'cancellation-policy',
    loadChildren: () => import('./pages/cancellation-policy/cancellation-policy.module').then( m => m.CancellationPolicyPageModule)
  },
  {
    path: 'faq',
    loadChildren: () => import('./pages/faq/faq.module').then( m => m.FaqPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
