import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { ActionPerformed, PushNotificationSchema, PushNotifications, Token } from '@capacitor/push-notifications';

// Service
import { RestApiService } from './../service/rest-api.service';

@Injectable({
  providedIn: 'root'
})
export class PushServiceService {
  notificationData: ActionPerformed;

  constructor( public restApi: RestApiService, public navCtrl: NavController) { }

  async addListeners() {
    await PushNotifications.addListener('registration', token => {
      console.info('Registration token: ', token.value);
      this.restApi.deviceToken = token.value;
      this.restApi.publishSomeData({ keepLogin: true });
    });
    await PushNotifications.addListener('registrationError', err => {
      // console.error('Registration error: ', err.error);
    });
    await PushNotifications.addListener('pushNotificationReceived', notification => {
      console.log('Push notification received: ', notification);
    });
    await PushNotifications.addListener('pushNotificationActionPerformed', tap => {
      console.log('Push notification action performed', tap.actionId, tap);
      localStorage.setItem('noti', JSON.stringify(tap));
      this.notificationAction()
    });
  }

  notificationAction() {
    const data = JSON.parse(String(localStorage.getItem('noti')));
    const sliced = data.notification.data
    if (sliced.MessageType == 'BusinessSuggestionRecieved' || sliced.MessageType == 'BusinessTipReceived' || sliced.MessageType == 'ChefReviewMention' || sliced.MessageType == 'ChefChefFeatureApproved' || sliced.MessageType == 'ChefChefFeatureDenied' || sliced.MessageType == 'ChefTipRecieved' || sliced.MessageType == 'ChefPhotoMention' || sliced.MessageType == 'ChefReviewReply') {
      this.navCtrl.navigateForward([`/restaurant/${JSON.parse(sliced.EntityID)}`]);
      localStorage.removeItem('noti');
    } else if (sliced.MessageType == 'BusinessChefFeatureApproved' || sliced.MessageType == 'BusinessChefFeatureDenied' || sliced.MessageType == 'BusinessChefAdded' || sliced.MessageType == 'BusinessChefRemoved') {
      this.navCtrl.navigateForward([`user/${JSON.parse(sliced.EntityID)}`]);
      localStorage.removeItem('noti');
    } else {
      localStorage.removeItem('noti');
    }
  }

  async registerNotifications() {
    let permStatus = await PushNotifications.checkPermissions();
    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
    }
    if (permStatus.receive !== 'granted') {
      throw new Error('User denied permissions!');
    }
    await PushNotifications.register();
  }

  async getDeliveredNotifications() {
    const notificationList = await PushNotifications.getDeliveredNotifications();
    // console.log('delivered notifications', notificationList);
  }
  async removeAllListeners(){
    await PushNotifications.removeAllListeners();
  }
  async removeAllDeliveredNotifications(){
    await PushNotifications.removeAllDeliveredNotifications()
  }
}
