import { Injectable, Inject, LOCALE_ID } from '@angular/core';
import { formatCurrency } from '@angular/common';
import { ToastController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class GlobalVariable {
    user: any;
    boatOwnerPendingRating: any[] = [];
    dockOwnerPendingRating: any[] = [];

    internetAlert: any;
    internetAlertActive: boolean = false;

    platformPWA: boolean = false;
    iabTarget = '_blank';
    locationAlertClosed: boolean = false;

    skipStripe: boolean = false;
    redirectUrl_manual = '';
    
    constructor(public toastCtrl: ToastController, public translate: TranslateService, private platform: Platform, @Inject(LOCALE_ID) public locale: string,) {
        console.log("Global Locale -> ", this.locale)
    }

    capitalizeSentences(input: string): string {
        let sentences = input;
        sentences = sentences.charAt(0).toUpperCase() + sentences.slice(1);
        return sentences;
    }

    formatDate(date) {
        let d = new Date(date),
            day = '' + d.getDate(),
            month = '' + (d.getMonth() + 1),
            year = d.getFullYear();
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        return [year, month, day].join('-');
    }

    styleDate(date, styleType) {
        let d = new Date(date),
            day = '' + d.getDate(),
            month = '' + (d.getMonth() + 1),
            year = d.getFullYear();
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        return [month, day, year].join(styleType);
    }

    getDateMonth(date) {
        let d = new Date(date),
            day = '' + d.getDate(),
            month = '' + (d.getMonth() + 1),
            year = d.getFullYear();
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        return month
    }

    unixToDate(unix) {
        return new Date(unix * 1000);
    }

    getTranslation(key) {
        return this.translate.instant(key);
    }

    formatCurrency(value, currencyCode, digitsInfo) {
        return formatCurrency(value, this.locale, '', currencyCode, digitsInfo);
    }

    customTrim(input: string): string {
        let start = 0;
        let end = input.length - 1;

        while (start <= end && input[start] === ' ') {  
          start++;
        }
  
        while (end >= start && input[end] === ' ') {
          end--;
        }
        return input.substring(start, end + 1);
    }
}