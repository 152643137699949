import { Component, ViewChild } from '@angular/core';
import { Platform, IonRouterOutlet, NavController, ModalController, AlertController } from '@ionic/angular';
import { NavigationExtras, Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { filter, startWith, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

// Plugins
import { SplashScreen } from '@capacitor/splash-screen';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { InAppBrowser, InAppBrowserEvent, InAppBrowserOptions } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';

// NPM Package
import { MediaPermissionsError, requestMediaPermissions } from 'mic-check';

// Service
import { RestApiService } from './service/rest-api.service';
import { environment } from '../environments/environment';
import { GlobalVariable } from '../app/service/global';
import { UtilsService } from '../app/service/utils.service';
import { PushServiceService } from './service/push-service.service';

// Modal
import { PendingReviewPage } from 'src/app/modal/pending-review/pending-review.page'

declare var cordova: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  private ngUnsubscribe = new Subject<void>();
  @ViewChild(IonRouterOutlet, { static: false }) routerOutlet: IonRouterOutlet;
  appRun = 0;

  constructor(private platform: Platform, public global: GlobalVariable, public utils: UtilsService,
    public navCtrl: NavController, public restApi: RestApiService, private network: Network,
    public translate: TranslateService, private router: Router, public device: Device, private modalCtrl: ModalController,
    private screenOrientation: ScreenOrientation, private androidPermissions: AndroidPermissions, private iab: InAppBrowser,
    public pushService: PushServiceService, private alertController: AlertController, public appVersion: AppVersion) {

    platform.ready().then(async () => {
      const subscription = this.router.events.subscribe((e) => {
        if (e instanceof NavigationEnd) {
          console.log("Current URL ", e.url);
          if(e.url == '/contact-us'){
            this.global.redirectUrl_manual = 'contact-us';
            subscription.unsubscribe();
          }
        }
      });

      console.log("Platform PWA height -> ", this.platform.height());
      console.log("Platform PWA width -> ", this.platform.width());
      console.log("Platform pwa -> ", this.platform.is('pwa'));
      console.log("Platform desktop -> ", this.platform.is('desktop'));
      console.log("Platform mobileweb -> ", this.platform.is('mobileweb'));
      console.log("Platform cordova -> ", this.platform.is('cordova'));
      console.log("Platform hybrid -> ", this.platform.is('hybrid'));
      // if (this.platform.is('desktop') || this.platform.is('pwa')) {
      //   console.log("Platform PWA");
      //   this.global.platformPWA = true;
      //   this.global.iabTarget = '_self';
      // } else {
      //   console.log("Platform is not PWA");
      //   this.global.platformPWA = false;
      //   this.global.iabTarget = '_blank';
      // }
      // this.requestWebCamPermission();
      if (this.platform.height() >= 415 && this.platform.width() > 768) {
        console.log("Platform PWA");
        this.global.platformPWA = true;
        this.global.iabTarget = '_blank';
      } else {
        console.log("Platform is not PWA");
        this.global.platformPWA = false;
        this.global.iabTarget = '_blank';
      }

      this.translate.setDefaultLang('en');
    
      if (localStorage.getItem("language") == null || localStorage.getItem("language") == undefined) {
        this.translate.use('en');
      } else {
        this.translate.use(String(localStorage.getItem("language")));
      }
      this.platform.backButton.subscribe(() => {
        if (!this.routerOutlet.canGoBack()) {
          (navigator as any)['app'].exitApp();
        }
      });

      if (localStorage.getItem("skipStripe") == 'false' || localStorage.getItem("skipStripe") == null || localStorage.getItem("skipStripe") == undefined) {
        this.global.skipStripe = false;
      } else {
        this.global.skipStripe = true;
      }
      console.log("Skip Stripe -> ", this.global.skipStripe)
      this.initializeApp();
    })
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      console.log("App component")
      document.addEventListener('deviceready', () => {
        this.clearNotificationBadge();
      });
      SplashScreen.show({ autoHide: false });
      
      // Uncomment Screen Orientation code for Android & IOS 
      // this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);

      this.global.user = JSON.parse(localStorage.getItem('userData')!);
      this.appRun = Number(JSON.parse(localStorage.getItem('appRun')!));

      this.requestCameraPermission();

      window.addEventListener('offline', () => {
        console.log("Offline Window Listener")
        this.hideSplash();
        this.global.internetAlertActive = false;
        // this.utils.internetAlert(this.translate.instant('networkHeading'), this.translate.instant('networkSubHeading'));
      });

      window.addEventListener('online', () => {
        console.log("Online Window Listener");
        this.global.internetAlertActive = false;
        this.global.internetAlert.dismiss();
      });

      // watch network for a disconnection
      let disconnectSubscription = this.network.onDisconnect().subscribe(() => {
        this.platform.backButton.subscribeWithPriority(9999, () => {
          document.addEventListener('backbutton', function (event) {
            event.preventDefault();
            event.stopPropagation();
            console.log('Back Button Disable');
          }, false);
        });
        this.utils.internetAlert(this.translate.instant('networkHeading'), this.translate.instant('networkSubHeading'));
      });

      let connectSubscription = this.network.onConnect().subscribe(() => {
        console.log('network connected!', this.router.url);
        this.platform.backButton.observers.pop();
        this.global.internetAlert.dismiss();
        this.global.internetAlertActive = false;
        this.utils.presentToast(this.translate.instant('networkConnected'), 2000, 'success', 'bottom');

        if (this.global.user && this.router.url == '/home') {
          if(this.global.platformPWA){
            this.keepLoginApi();
          }
          if(!this.global.platformPWA){
            // Uncomment Push Notification code for Android & IOS 
            // this.pushService.registerNotifications();
            // this.pushService.getDeliveredNotifications();
            // this.pushService.addListeners();

            this.restApi.getObservable().subscribe(data => {
              if (data.keepLogin) { 
                this.keepLoginApi();
              } 
            });
          }
        }
      });
      
      if(!this.global.platformPWA){
        // Uncomment Push Notification code for Android & IOS 
        // this.pushService.registerNotifications();
        // this.pushService.getDeliveredNotifications();
        // this.pushService.addListeners();
      }

      if (this.global.user) {
        if(this.global.platformPWA){
          this.keepLoginApi();
        }
        if(!this.global.platformPWA){
          // Uncomment Push Notification code for Android & IOS 
          // this.pushService.registerNotifications();
          // this.pushService.getDeliveredNotifications();
          // this.pushService.addListeners();

          this.restApi.getObservable().subscribe(data => {
            if (data.keepLogin) { 
              this.keepLoginApi();
            } 
          });
        }
      } else {
        this.hideSplash();
        
        if(!this.appRun){
          localStorage.setItem("appRun", JSON.stringify(1));
          // this.navCtrl.navigateRoot('home');
          if(!this.global.platformPWA){
            this.navCtrl.navigateRoot('tabs');
          } else {
            this.navCtrl.navigateRoot('home');
          }
        } else {
          localStorage.setItem("appRun", JSON.stringify(1));
          // this.navCtrl.navigateRoot('home');
          if(!this.global.platformPWA){
            this.navCtrl.navigateRoot('tabs');
          } else {
            if(!this.global.user){
              this.navCtrl.navigateRoot('home');
            } else {
              if(this.global.user.SwitchUserType == "BoatOwner"){
                this.navCtrl.navigateRoot('home');
              } else {
                this.navCtrl.navigateRoot('booked-rentals');
              }
            }
          }
        }
      }
    });
  }

  hideSplash() {
    setTimeout(async () => {
      await SplashScreen.hide();
    }, 2000);
  }

  async keepLoginApi(){
    let deviceType = '';
    if(this.platform.is('ios')){
      deviceType = 'IOS';
    } else if(this.platform.is('android')){
      deviceType = 'Android';
    } else {
      deviceType = 'Web';
    }

    let AppVersion: any;
    if (this.platform.is('cordova')) {
      await this.appVersion.getVersionNumber().then(res => {
        AppVersion = res;
      })
    } else {
      AppVersion = "2.4"
    }

    let body = {
      "Email": this.global.user.Email,
      "UserID": this.global.user.UserID,
      "AppVersion": AppVersion,
      "DeviceOSVersion": this.device.version ? this.device.version : "1.6",
      "DeviceToken": this.restApi.deviceToken,
      "DeviceType": deviceType
    }

    this.restApi.apiCall('post', 'Users/KeepLogin', body)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(async (res) => {
      // this.utils.stopLoading();
      // loading.dismiss();
      console.log("Keep Login API -> ", res)
      if(res.response.Code == 0){
        console.log("User Info Keep Login -> ", res.response.DataObject.UserData.userInfo);
        localStorage.setItem("userData", JSON.stringify(res.response.DataObject.UserData.userInfo));
        this.global.user = res.response.DataObject.UserData.userInfo;
        this.global.boatOwnerPendingRating = res.response.DataObject.UserData.boatOwnerPendingRating;
        this.global.dockOwnerPendingRating = res.response.DataObject.UserData.dockOwnerPendingRating;

        // Pending Reviews
        if (this.global.user.SwitchUserType == "BoatOwner") {
          if(this.global.boatOwnerPendingRating.length > 0){
            this.openReviewModal(this.global.boatOwnerPendingRating[0]);
          }
        } else {
          if(this.global.dockOwnerPendingRating.length > 0){
            this.openReviewModal(this.global.dockOwnerPendingRating[0]);
          }
        }

        if (res.response.DataObject.UserData.userInfo.ForceUpdate) {
          this.hideSplash();
          const button = [{
            text: this.translate.instant('update'),
            role: 'confirm',
            handler: () => {
              window.open(res.response.DataObject.UserData.userInfo.LiveAppLink, '_system');
              return false
            },
          }]
          this.utils.presentAlert(this.translate.instant('alert'), res.response.DataObject.UserData.userInfo.EnforcementMessage, button, false)
        }

        // Check for terms & Conditions and Privacy policy
        if(this.global.user.IsVerified){
          this.hideSplash();

          if(!this.global.user.TermsAccepted){
            this.hideSplash();
            let navigationExtras: NavigationExtras = {
              state: {
                fromPage: 'signUp'
              }
            };
            this.navCtrl.navigateRoot('/term-condition', navigationExtras);
          }

          if(this.global.user.TermsAccepted){
            this.hideSplash();
            if (this.global.user.SwitchUserType == "BoatOwner") {
              if(this.global.user.VerificationIdentifier){
                if(this.global.user.verificationstatus == 'Incomplete' || this.global.user.verificationstatus == ''){
                  this.verificationRequest();
                  // if(this.global.platformPWA){
                  //   this.requestWebCamPermission();
                  // } else {
                  //   this.verificationRequest();
                  // }
                } else if(this.global.user.verificationstatus == 'Confirmed') {
                  if(!this.global.platformPWA){
                    this.navCtrl.navigateRoot('tabs');
                  } else {
                    this.navCtrl.navigateRoot('home');
                  }
                } else {
                  this.utils.superhogFailureAlert(this.translate.instant('superhogFailureHeading'), this.translate.instant('superhogFailureMsg'))
                } 
              } else {
                // call verification API and move to webview
                this.verificationRequest();
              }
            } else {
              if(!this.global.platformPWA){
                this.navCtrl.navigateRoot('tabs/booked-rentals');
              } else {
                this.navCtrl.navigateRoot('booked-rentals');
              }
              // if(this.global.user.IsStripeAccountCreated){
              //   if(!this.global.platformPWA){
              //     this.navCtrl.navigateRoot('tabs/booked-rentals');
              //   } else {
              //     this.navCtrl.navigateRoot('booked-rentals');
              //   }
              // } else {
              //   this.navCtrl.navigateRoot('stripe');
              // }
            }
          }
        } else {
          this.hideSplash();
          this.navCtrl.navigateRoot('otp');
        }
      } 

      if(res.response.Code == 4){
        this.hideSplash();
        this.utils.presentToast(res.response.Status, 5000, 'danger', 'bottom');
      }
    }, error => {
      // loading.dismiss();
      this.utils.presentToast(error.error.title, 2000, 'danger', 'bottom');
      if (error.status == 0){
        
      }
    });
  }

  async verificationRequest(){
    if(this.global.user.VerificationUrl){
      this.openBrowser(this.global.user.VerificationUrl, this.global.user.RedirectUrl);
    } else {
      this.utils.showLoading(this.translate.instant('pleaseWait'));
  
      this.restApi.apiCall('GET', 'Superhog/VerificationRequest/' + this.global.user.UserID, '')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.utils.stopLoading();
        console.log("Verification Request API -> ", res)
        if(res.response.Code == 0){
          this.openBrowser(res.response.DataObject.VerifiedRequest.url, res.response.DataObject.VerifiedRequest.redirectUrl);
        }
       
        if(res.response.Code == 4){
          this.utils.presentToast(res.response.Status, 2000, 'danger', 'bottom');
        }
      }, error => {
        this.utils.stopLoading();
        this.utils.presentToast(error.error.title, 2000, 'danger', 'bottom');
      });
    }
  }

  async openBrowser(url, redirectUrl){
    let options: InAppBrowserOptions = {
      toolbar: 'no',
      hidespinner: 'no',
      hideurlbar:'yes',
      location: "no",
      hidenavigationbuttons: "yes",
    };

    var browser = this.iab.create(url, this.global.iabTarget, options);
    
    browser.on("loadstart").subscribe((event: InAppBrowserEvent) => {
      console.log("URL Listener -> ", event.url)

      if(event.url.includes(redirectUrl)){
        browser.close();
        if(this.global.user.isGuestUserSuperhogVerified){
          // this.navCtrl.navigateRoot('home');
          if(!this.global.platformPWA){
            this.navCtrl.navigateRoot('tabs');
          } else {
            this.navCtrl.navigateRoot('home');
          }
        } else {
          this.navCtrl.navigateRoot('trigger-webhook');
        }
      }
    });
  }

  async openReviewModal(detail) {
    const modal = await this.modalCtrl.create({
      component: PendingReviewPage,
      cssClass: 'reviewModal-cssClass',
      componentProps: {
        'detail': detail,
      }
    });
    modal.present();
    
    const { data, role } = await modal.onWillDismiss();

    if (data.success) {
      // Pending Reviews
      if (this.global.user.SwitchUserType == "BoatOwner") {
        if(this.global.boatOwnerPendingRating.length > 0){
          this.openReviewModal(this.global.boatOwnerPendingRating[0]);
        }
      } else {
        if(this.global.dockOwnerPendingRating.length > 0){
          this.openReviewModal(this.global.dockOwnerPendingRating[0]);
        }
      }
    }
  }

  async requestCameraPermission(){
    this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.CAMERA).then((async result => {
      if(result.hasPermission){
        
      } else {
        this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.CAMERA).then((async result => {
          if(result.hasPermission){
          }
        }),(err => { 
  
        }));
      }
    }),(err => { 

    }));
  }

  async requestWebCamPermission(){
    navigator.mediaDevices.getUserMedia({ video: true }).then(stream => {
      console.log("User Media Permission Allowed ", stream)
      // do something with the stream
      // this.verificationRequest();
    }).catch(err => {
      // handle errors
      console.log("User Media Permission Err ", err)
      requestMediaPermissions({audio: false, video: true}).then((res) => {
        console.log("Request Media Permission", res)
      }).catch((err: MediaPermissionsError) => {
        console.log("Request Media Permission Error", err)
        if(err.type == 'UserPermissionDenied'){
          this.requestWebCamAlert('webcamAlertHeader');
        }
        if(err.type == 'SystemPermissionDenied'){
          this.requestWebCamAlert('webcamBrowserAlertHeader');
        }
        if(err.type == 'CouldNotStartVideoSource'){
          this.requestWebCamAlert('webcamCouldNotStartAlertHeader');
        }
      });
    });
  }

  async requestWebCamAlert(header){
    const alert = await this.alertController.create({
      header: this.translate.instant(header),
      buttons: [
        {
          text: this.translate.instant('cancel'),
          role: 'cancel',
          handler: () => {
            
          },
        },
        {
          text: this.translate.instant('retry'),
          role: 'confirm',
          handler: () => {
            this.requestWebCamPermission();
          },
        },
      ],
    });

    await alert.present();
  }

  clearNotificationBadge() {
    if (typeof cordova !== 'undefined' && cordova.plugins.notification) {
      cordova.plugins.notification.badge.clear();
    }
  }
}
