<ion-content>
  <ion-row>
    <ion-col size="12">
      <h4 class="p-0 mb-0 text-center bold">{{ detail?.DockName }}</h4>
      <p class="p-0 m-0 text-center bold">{{ detail?.BookingFrom }} - {{ detail?.BookingTo }}</p>

      <p class="mt-1 mb-0 text-center bold">{{ 'pendingReview.experience' | translate }}</p>
      <ion-rating-stars class="text-center" [rating]="1" [color]="'darkgrey'" [filledColor]="'#FFC312'" [margin]="1"
        [size]="30" (ratingChange)="ratingChanged($event)"></ion-rating-stars>

      <div class="main-div">
        <div class="items-div">
          <ion-row>
            <!-- <ion-col size-sm="4" class="p-0"></ion-col> -->
    
            <ion-col size="12" size-sm="12" class="p-0">
              <ion-item class="ion-item-input mt-1" lines="none" mode="ios">
                <ion-label position="stacked">{{ 'pendingReview.leaveReview' | translate }}</ion-label>
                <ion-textarea id="message" autocapitalize="sentences" (ionChange)="capitalizeChange()" style="--placeholder-color: #999 !important;" class="placeholder-black"
                  [placeholder]="'home.messagePlaceholder' | translate" [(ngModel)]="message" type="text" [autoGrow]="true"
                  rows="4"></ion-textarea>
              </ion-item>

              <ion-button class="primary-btn" color="primary" expand="block" (click)="submitReview()">{{ 'pendingReview.submitBtn' | translate }}</ion-button>
            </ion-col>

            <!-- <ion-col size-sm="4" class="p-0"></ion-col> -->
          </ion-row>
        </div>
      </div>
    </ion-col>
  </ion-row>
</ion-content>